import React, { useState } from "react";
import SectionWrapper, { Container } from "./contactDetails.style";
import Fade from "react-reveal/Fade";
import axios from "axios";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import the default styles
import { ColorRing } from 'react-loader-spinner'; // Import the loader

const formStyle = {
  color: "black",
};

const labelStyle = {
  display: "block",
  marginBottom: "5px",
};

function ContactDetails() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    services: "",
    officeHour: "",
    date: "",
    description: "",
  });

  const [errors, setErrors] = useState({});
  const [showThankYou, setShowThankYou] = useState(false); // State for thank you popup
  const [loading, setLoading] = useState(false); // State for loading

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });

    if (name === "date") {
      if (value !== '') {
        e.target.classList.add('has-value');
      }
    }
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
    setErrors({
      ...errors,
      phone: "",
    });
  };

  const handleKeyUp = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };
    if (name === "phone" && value.length > 10 && !/^\d{12}$/.test(value)) {
      newErrors.phone = "Phone number only 10 digits";
    } else {
      delete newErrors.phone;
    }
    setErrors(newErrors);
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Phone is required";
    } else if (!/^\d{12}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be exactly 10 digits";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        console.log("Submitting data:", formData); // Debug: log form data
        const token = "CmcXDorMLw8mj8ZlJI1aE1f2U1foRfn8"; // Replace with actual token
        const response = await axios.post(
          "https://www.radiancehairstudio.com/api/contact.php",
          formData,
          {
            headers: {
              token: token,
            },
          }
        );
        console.log("Data submitted successfully:", response.data);
        setShowThankYou(true);
        // Clear form data after successful submission
        setFormData({
          name: "",
          email: "",
          phone: "",
          services: "",
          officeHour: "",
          date: "",
          description: "",
        });
      } catch (error) {
        console.error("Error submitting data:", error);
      }finally {
        setLoading(false); // Hide progress indicator
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <SectionWrapper id="contactDetails">
      <Container>
        <div className="row">
          <div className="col-md-6">
            <Fade left>
              <div className="location-details-wrap">
                <div class="locations">
                  <div className="row">
                    <div class="loc1 col-md-6">
                      <div className="flex">
                        <img src="/home/map.svg" alt="map" />
                        <h4>Rajouri Garden</h4>
                      </div>
                    </div>
                    <div class="loc2 col-md-6">
                      <div
                        className="flex"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <img src="/home/phone.svg" alt="phone" />
                        <h4><a href="tel:+919958585649">+91 99585-85649</a></h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="loc3 col-md-12">
                      <p>
                        <a
                          href="https://maps.app.goo.gl/7VU57nyvQzby7Wf7A"
                          target="_blank"
                        >
                         A-66, 3rd Floor, Block A, Block A2, Block A, Rajouri Garden, New Delhi, Delhi, 110027 
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="locations">
                  <div className="row">
                    <div class="loc1 col-md-6">
                      <div className="flex">
                        <img src="/home/map.svg" alt="map" />
                        <h4>CR Park</h4>
                      </div>
                    </div>
                    <div class="loc2 col-md-6">
                      <div
                        className="flex"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <img src="/home/phone.svg" alt="phone" />
                        <h4><a href="tel:+918448721457">+91 84487-21457</a></h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="loc3 col-md-12">
                      <p>
                        <a
                          href="https://maps.app.goo.gl/fhrnKp7Fef9hrY7H8"
                          target="_blank"
                        >
                          B-311, CR Park Main Rd, Block B, Chittaranjan Park, New Delhi, Delhi 110019
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="locations mb-0">
                  <div className="row">
                    <div class="loc1 col-md-6">
                      <div className="flex">
                        <img src="/home/map.svg" alt="map" />
                        <h4>Dwarka</h4>
                      </div>
                    </div>
                    <div class="loc2 col-md-6">
                      <div
                        className="flex"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <img src="/home/phone.svg" alt="phone" />
                        <h4><a href="tel:+918595982929">+91 8595982929</a></h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="loc3 col-md-12">
                      <p>
                        <a
                          href="https://maps.app.goo.gl/njfjp8E6wvdgn79ZA"
                          target="_blank"
                        >
                          960, 3rd Floor, Shop No. H, Gali Number 8, Harijan Basti, Sector 7 Dwarka, Dwarka, New Delhi, Delhi, 110075 
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row"
                style={{ alignItems: "center", marginTop: "25px" }}
              >
                <div className="col-md-6">
                  <h3>
                    Follow us and <br />
                    stay tuned
                  </h3>
                </div>
                <div className="col-md-6">
                  <ul
                    className="topRight-t flex social-icons"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <li>
                      <a
                        href="https://www.facebook.com/radiancehairstudio1"
                        target="_blank"
                      >
                        <i className="flaticon-facebook-logo"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/studio_radiance"
                        target="_blank"
                      >
                        <i className="flaticon-twitter-logo-silhouette"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCLWRJlt4j04qSGUjvBThbig"
                        target="_blank"
                      >
                        <i className="flaticon-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/radiancehairofficial/"
                        target="_blank"
                      >
                        <i className="flaticon-instagram"></i>
                      </a>
                    </li>

                    {/* <li>
                    <a href="#">
                      <i className="flaticon-dribble-logo"></i>
                    </a>
                  </li> */}
                  </ul>
                </div>
              </div>
            </Fade>
          </div>

          <div className="col-md-6 form_ARaea">
            <Fade right>
              <div>
                <h3 style={{ color: "#333333" }}>Notice <a href="/services.php" className="text-black">hair loss?</a></h3>
                <p>Get in touch with our experts now</p>
                <form
                  style={formStyle}
                  onSubmit={handleSubmit}
                  id="footerContactForm"
                >
                  <div className="formRow">
                    <div className="formControlss">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Name"
                      />
                      {errors.name && (
                        <span className="error">{errors.name}</span>
                      )}
                    </div>
                    <div className="formControlss">
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email"
                      />
                 
                    </div>
                  </div>
                  <div className="formRow">
                    <div className="formControlss">
                    <PhoneInput
                        country={'in'}
                        value={formData.phone}
                        onChange={handlePhoneChange}
                        onBlur={handleKeyUp}
                        inputProps={{ placeholder: "+91 1234567890", style: formStyle }}
                      />
                      {errors.phone && (
                        <span className="error">{errors.phone}</span>
                      )}
                    </div>
                    <div className="formControlss">
                      <select
                        className="custom-select d-block form-control"
                        id="services"
                        name="services"
                        value={formData.services}
                        onChange={handleChange}
                        placeholder="Services"
                      >
                        <option value="Select Services">Select Services</option>
                        <option value="consultation & free demo">
                          Consultation & Free Demo
                        </option>
                        <option value="hair bonding">Hair Bonding</option>
                        <option value="shampoo setting">Shampoo Setting</option>
                        <option value="tape-in">Tape-in</option>
                        <option value="others">Others</option>
                      </select>
                
                    </div>
                  </div>

                  <div className="formRow">
                    <div className="formControlss">
                      <div className="input-group date" id="datetimepicker1">
                        <input
                          type="date"
                          id="date"
                          className="form-control input-lg"
                          placeholder="dd-mm-yyyy"
                          name="date"
                          value={formData.date}
                          onChange={handleChange}
                        />
                        <span className="input-group-addon">
                          <span className="glyphicon glyphicon-calendar"></span>
                        </span>
                      </div>
                 
                    </div>
                    <div className="formControlss">
                      <select
                        className="custom-select d-block form-control"
                        id="officeHour"
                        name="officeHour"
                        value={formData.officeHour}
                        onChange={handleChange}
                      >
                        <option value="Select an option">
                          Select office hour
                        </option>
                        <option value="11:00AM -	12:00PM">11:00AM - 12:00PM</option>
                        <option value="12:00AM - 01:00PM">12:00AM - 01:00PM</option>
                        <option value="1:00PM - 2:00PM">1:00PM - 2:00PM</option>
                        <option value="2:00PM - 3:00PM">2:00PM - 3:00PM</option>
                        <option value="3:00PM - 4:00PM">3:00PM - 4:00PM</option>
                        <option value="4:00PM - 5:00PM">4:00PM - 5:00PM</option>
                        <option value="5:00PM - 6:00PM">5:00PM - 6:00PM</option>
                        <option value="6:00PM - 7:00PM">6:00PM - 7:00PM</option>
                        <option value="7:00PM - 8:00PM">7:00PM - 8:00PM</option>
                      </select>
                
                    </div>
                  </div>

                  <div className="formControlss">
                    <textarea
                      id="description"
                      name="description"
                      className="form-control"
                      value={formData.description}
                      onChange={handleChange}
                      rows={4}
                      placeholder="Your message"
                    ></textarea>
                  </div>

                  <div className="formControlss">
                  {loading ? (
                    
                     <ColorRing
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']} // Add a class to ensure it is correctly positioned
                      />
                   
                      
                    ) : (
                      <button type="submit">Submit</button>
                    )}
                  </div>
                </form>
              </div>
            </Fade>
          </div>
        </div>
        {showThankYou && (
            <div className="thankyou-popup">
              <div className="thankyou-content">
                <p>Thank you for your submission!</p>
                <button className="close-btn" onClick={() => setShowThankYou(false)}>Close</button>
              </div>
            </div>
          )}
      </Container>
    </SectionWrapper>
  );
}

export default ContactDetails;
